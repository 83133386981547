import { dateTimeNow } from '../services/DateTimeService'
import Flag from './Flag'
import LocationModel from '@/model/LocationModel'
import ArticleMainRubricPosition from '@/model/ArticleMainRubricPosition'

export const ARTICLE_LAYOUT_PRIMARY = 'primary'
export const ARTICLE_LAYOUT_FULLWIDTH = 'fullwidth'

export const ARTICLE_LAYOUT = [ARTICLE_LAYOUT_PRIMARY, ARTICLE_LAYOUT_FULLWIDTH]
export const ARTICLE_READER_ACCESSIBILITY = ['all', 'logged', 'subscribers']
export const ARTICLE_BADGES = ['weKnowFirst', 'exclusive', 'weWillUpdate', 'weWatchOnline', 'extraordinaryMessage', 'dailyForGoodVibe', 'dailyAgainstFakeNews', 'trueAndLie', 'warProgress', 'fakeNews', 'peopleAndWar', 'podcast', 'commercialCommunication']

const ARTICLE_TYPE_ARTICLE = 'article'

export default {
  id: null,
  type: ARTICLE_TYPE_ARTICLE,
  firstPublishedDate: dateTimeNow(),
  orderDate: dateTimeNow(),
  publishedSince: dateTimeNow(),
  publishedUntil: null,
  site: {
    id: null,
    name: '',
    title: ''
  },
  mainRubric: {
    id: null,
    title: ''
  },
  field: {
    alternativeTitle: '',
    title: '',
    subTitle: '',
    shortTitle: '',
    firstParagraph: '',
    listingTeaser: '',
    bodyText: '',
    videoType: 'none',
    video: '',
    source: '',
    pageTitles: [],
    printCategory: ''
  },
  external: {
    siteName: '',
    siteUrl: '',
    rubricName: '',
    rubricUrl: '',
    url: '',
    author: ''
  },
  heroImage: null,
  listingImage: null,
  flag: Flag,
  setting: {
    status: '',
    hidden: 0,
    heroImageDisplayType: 'small',
    proofreadStatus: '',
    disableComments: 0,
    disableAdvert: 0,
    prArticle: 0,
    editorialArticle: 0,
    copiedFromPrint: 0,
    paidContent: 0,
    readersAccessibility: null,
    migratedSystem: '',
    migratedId: '',
    migratedRemoteId: '',
    scheduledPublish: 0,
    layout: ARTICLE_LAYOUT_PRIMARY,
    disableTransfer: 0,
    abTested: false,
    dynamicPreview: false,
    fbShared: false,
    editedInNewEditor: true,
    showCommercials: false,
    showDiscussion: null,
    enabledFromWeb: true,
    showRating: false
  },
  aiToolsUsed: [],
  routes: [],
  doctors: [],
  authors: [],
  mainAuthor: {
    id: null,
    name: '',
    originName: ''
  },
  tags: [],
  geneeaTags: [],
  selectedTopics: [],
  categories: [],
  rubrics: [],
  safetyTopics: [],
  gallery: [],
  owners: [],
  articleLinks: [],
  articleEmbeds: [],
  articleTest: null,
  relatedArticles: [],
  expanded: {
    site: null, // object with 'id', 'name', 'title', 'shortTitle'
    mainRubric: null, // object with 'id', 'name', 'title'
    rubrics: [], // objects with 'id', 'name', 'title', 'parent'
    doctors: [], // objects with 'id', 'name'
    authors: [], // objects with 'id', 'name', 'firstName', 'lastName', 'customText'
    mainAuthor: null, // object with 'id', (name' || 'title' ???), 'firstName', 'lastName', 'customText'
    tags: [], // objects with 'id', 'title'
    selectedTopics: [], // objects with 'id', 'title'
    owners: [], // objects with 'id', 'username'
    sharedUsers: [], // objects with 'id', 'username'
    categories: [], // objects with 'id', 'title'
    safetyTopics: [], // this is only used as  'article.safetyTopics' and not as 'article.expanded.safetyTopics'
    heroImage: null, // core image object
    listingImage: null, // core image object
    gallery: [], // core image objects
    quiz: null, // object with 'id', 'title'
    recipe: null, // object with 'id', 'title'
    articleTest: null, // object with 'id', 'title'
    printPublication: null // object with 'id', 'title' 'printTitleInfo.title'
  },
  quiz: null,
  printPublication: null,
  abTestVariants: [
    {
      variant: 'a',
      title: '',
      image: null
    },
    {
      variant: 'b',
      title: '',
      image: null
    },
    {
      variant: 'c',
      title: '',
      image: null
    }
  ],
  hpTopicOffer: [],
  woodWingExportDate: null,
  bodyBlocks: [],
  actualisationDate: null,
  sourceAgencyId: null,
  articleBadges: [],
  location: LocationModel,
  summary: '',
  mainRubricPosition: ArticleMainRubricPosition,
  oneSignalNotified: false,
  notificationPostponed: false
}
