export default {
  title: '',
  searchIn: 'title',
  id: '',
  site: 0,
  name: '',
  rubric: 0,
  type: '',
  authors: [],
  users: [],
  tags: [],
  categories: [],
  dateFrom: null,
  dateTo: null,
  status: 'all',
  documentIds: [],
  includeSharedArticles: false
}
